.phantom_modal {
    margin-top: 12%;
  }
.phantom_modal_body {
  padding: 30px !important;
  width: 526px;
    text-align: center;
    border-radius: 10px;
  background-color: #2E2E2E;
  box-shadow: 0 2px 10px 5px rgba(0,0,0,0.17);
  }
  .phantom_logo{
    height: 70px;
  }
  .connect_phantom_wallet_button {
    margin: auto;
    width: 300px;
    border-radius: 25px;
    background: linear-gradient(180deg, #524AB2 0%, #561FF7 100%);
    cursor: pointer;
  }
  .connect_phantom_wallet {
    color: #FFFFFF;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    padding: 15px;
  }
  .or_download_here {
    margin-top: 35px;
    color: #FFFFFF;
    font-family: "Open Sans";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
    cursor: pointer;
  }