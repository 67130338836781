.machine-container {
  display: flex;
  flex-direction: column;
}

.gif-container {
  display: flex;
  flex-direction: column;
}

.gif-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1.5rem;
  justify-items: center;
  margin: 0;
  padding: 20px 0 20px 0;
}

.gif-grid .gif-item {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-self: center;
  align-self: center;
}

.gif-item img {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
}
.mint_button {
  border-radius: 25px;
background: linear-gradient(180deg, #F1D487 0%, #B66F46 100%);
  width: 180px;
  padding: 10px;
  text-align: center;
  margin: auto 20px auto auto;
  color: white;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  cursor: pointer;
  margin: auto;

}